import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChatMessagesProvider } from './context/ChatProvider';
import { MetadataProvider } from './context/MetadataContext';

const getMetadataFromAttributes = () => {
  const chatbotWidget = document.querySelector('chatbot-widget');
  if (chatbotWidget) {
    return {
      logo_png: chatbotWidget.getAttribute('data-logo-png') || 'https://imgur.com/4401jtu.png',
      icon_png: chatbotWidget.getAttribute('data-icon-png') || 'https://i.imgur.com/4401jtu.png',
      name: chatbotWidget.getAttribute('data-name') || 'DMCC',
      apikey: chatbotWidget.getAttribute('data-apikey') || '008e6f40-1ae7-474d-bc6a-eded02037a15',
      endpoint: chatbotWidget.getAttribute('data-endpoint') || 'us/dmcc/eus1/conversational/ultra/v1/chat/completions',
      welcome_message: chatbotWidget.getAttribute('data-welcome-message') || 'Welcome to our custom chatbot!',
      primary_color: "#009933",
      secondary_color: "#4a4a4a"
    };
  }
  return {};
};

const metadata = getMetadataFromAttributes();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MetadataProvider>
      <ChatMessagesProvider>
        <App />
      </ChatMessagesProvider>
    </MetadataProvider>
  </React.StrictMode>
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
