import React, { createContext, useState, useEffect } from 'react';

// Default metadata values
let defaultMetadata = {
  logo_png: 'https://imgur.com/2klZ3NM.png',
  icon_png: 'https://us.floatbot.ai/portal/chatbot/images/chat1.svg',
  name: 'DMCC',
  apikey: 'e78037a15-1a00-474d-g9pe-eded020e6f4',
  endpoint: 'us/dmcc/eus1/conversational/ultra/v1/chat/completions',
  primary_color: "#080047",
  secondary_color: "#e40d75"
};
defaultMetadata['welcome_message'] = `Welcome to ${defaultMetadata.name}! 👋\n\nI'm the ${defaultMetadata.name} AI Assistant, here to help you with any questions or support you need. Feel free to ask me anything, and I'll do my best to assist you. Let's make your experience smooth and enjoyable. How can I help you today?`;
defaultMetadata['endpoint_url'] = `https://api.us.inc/${defaultMetadata.endpoint}`;


// Create the context
export const MetadataContext = createContext(defaultMetadata);

export const MetadataProvider = ({ children }) => {
  const [metadata, setMetadata] = useState(defaultMetadata);

  // useEffect(() => {
  //   const setMetadataFromAttributes = () => {
  //     const chatbotWidget = document.querySelector('chatbot-widget');
  //     if (chatbotWidget && chatbotWidget.shadowRoot) {
  //       const parentDiv = chatbotWidget.shadowRoot.querySelector('div');
  //       if (parentDiv) {
  //         const newMetadata = {
  //           logo_png: parentDiv.getAttribute('data-logo-png') || defaultMetadata.logo_png,
  //           icon_png: parentDiv.getAttribute('data-icon-png') || defaultMetadata.icon_png,
  //           name: parentDiv.getAttribute('data-name') || defaultMetadata.name,
  //           apikey: parentDiv.getAttribute('data-apikey') || defaultMetadata.apikey,
  //           endpoint: `https://13.248.139.177/${parentDiv.getAttribute('data-endpoint')}` || defaultMetadata.endpoint,
  //           welcome_message: parentDiv.getAttribute('data-welcome-message') || defaultMetadata.welcome_message,
  //         };
  //         setMetadata(newMetadata);
  //       } else {
  //       }
  //     } else {
  //     }
  //   };

  //   // Delay execution to ensure the element is fully initialized
  //   const delaySetMetadata = () => {
  //     console.log("delaySetMetadata -- ", delaySetMetadata);
  //     setTimeout(() => {
  //       setMetadataFromAttributes();
  //     }, 500);
  //   };

  //   // Observe changes to the DOM to detect when the chatbot-widget is added
  //   const observer = new MutationObserver((mutationsList, observer) => {
  //     console.log("observer -- ", observer);
  //     mutationsList.forEach(mutation => {
  //       if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
  //         delaySetMetadata();
  //       }
  //     });
  //   });
  //   observer.observe(document.body, { childList: true, subtree: true });

  //   // Initial call in case the element is already present
  //   delaySetMetadata();

  //   console.log("delaySetMetadata  disconnect -- ");

  //   return () => observer.disconnect();
  // }, []);

  return (
    <MetadataContext.Provider value={metadata}>
      {children}
    </MetadataContext.Provider>
  );
};