import styles from "./CommonMarkdown.module.scss";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import "katex/dist/katex.min.css";
import rehypeHighlight from "rehype-highlight";
import "highlight.js/styles/github.css";
import CodeBlock from "./CodeBlock";
import rehypeRaw from "rehype-raw";

import { languagesData } from "../../utils/language";

const CommonMarkdown = ({
  index,
  content,
  loading,
  currentAnswerIndex,
  selectedFeature,
}) => {
  // Custom renderers
  const renderers = {
    ul: ({ children }) => (
      <ul style={{ paddingTop: "0rem", lineHeight: "1.6rem" }}>{children}</ul>
    ),
    ol: ({ children }) => {
      return <ol>{children}</ol>;
    },
    li: ({ node, ...props }) => {
      return (
        <li style={{ marginBottom: "0px", whiteSpace: "normal" }} {...props} />
      );
    },

    code: ({ node, inline, className, children, ...props }) => {
      const match = /language-(\w+)/.exec(className || "");

      let matchLanguage = false;

      const isLanguageAvailable = (match) => {
        const lowerCaseMatch = match?.toLowerCase();
        return (
          languagesData?.find((language) => language === lowerCaseMatch) !==
          undefined
        );
      };

      if (isLanguageAvailable(match?.[1])) {
        // return;
        matchLanguage = true;
      }

      return !inline && match && matchLanguage ? (
        <CodeBlock
          language={match[1]}
          value={children}
          styles={styles}
          className={className}
          props={props}
        />
      ) : (
        <code className={`${className} code-additional-class`} {...props}>
          {children}
        </code>
      );
    },

    a: ({ node, ...props }) => (
      <a {...props} target="_blank" rel="noopener noreferrer" />
    ),

    // Add more renderers as needed
  };

  const remarkMathOptions = {
    singleDollarTextMath: false,
  };

  const preprocessMarkdown = (markdownText) => {
    const processedText = markdownText
      ?.replace(/\\\[/g, "$$$")
      ?.replace(/\\\]/g, "$$$")
      ?.replace(/\\\(/g, "$$$")
      ?.replace(/\\\)/g, "$$$");

    return processedText;
  };

  return (
    <div className="math-jax-container">
      <Markdown
        children={preprocessMarkdown(content)}
        remarkPlugins={[
          [remarkMath, remarkMathOptions],
          remarkGfm,
          // remarkEmoji,
        ]}
        rehypePlugins={[rehypeRaw, rehypeKatex, rehypeHighlight]}
        components={renderers}
        className={"markdown"}
      />
    </div>
  );
};

export default CommonMarkdown;
