import React, { useState, useRef, useEffect, useContext } from "react";
import { MetadataContext } from "../../context/MetadataContext";
import { ImSpinner8 } from "react-icons/im";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';

const ChatInput = ({ loading, input, setInput, handleSubmit }) => {
  const metadata = useContext(MetadataContext);
  const handleLoadingSubmit = (e) => {
    e.preventDefault();
  };

  const textareaRef = useRef(null);

  useEffect(() => {
    adjustTextareaHeight();
  }, [input]);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      // Reset the height to auto to shrink the textarea back to fit content
      textarea.style.height = 'auto';
      // Set the height based on the scroll height, but limit it to 3 lines
      const maxHeight = 14;
      const initScrollHeight = 40;
      
      textarea.style.height = (textarea.scrollHeight/initScrollHeight) <= 1 ? maxHeight : ((textarea.scrollHeight/initScrollHeight) <= 5?((textarea.scrollHeight/initScrollHeight)*(2*maxHeight)):(5*(2*maxHeight))) + 'px';
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (!e.shiftKey) {
        handleSubmit(e);
      }
    }
  };
  return (
    <form onSubmit={(e) => { handleSubmit(e); }} className="form-container">
      <div className="input-container">
        {/* <input
          type="text"
          value={input}
          required
          onChange={(e) => setInput(e.target.value)}
          placeholder="Ask any question..."
        /> */}
        <textarea
        ref={textareaRef}
          value={input}
          required
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Ask any question..."
          rows="1"
          style={{
            resize: 'none',
            overflow: 'hidden',
            minHeight: '1.5em',
            lineHeight: '1.5em'
          }}
        />
        <button type="submit">
          {loading ? (
            <ImSpinner8 size={24} className="spinner-icon" style={{ color: metadata.primary_color, cursor: "default" }} />
          ) : (
            <FontAwesomeIcon width={18} icon={faPaperPlane} style={{ color: metadata.primary_color, paddingRight: "5px" }} />
          )}
        </button>
      </div>
    </form>
  );
};

export default ChatInput;
