import axios from 'axios';

export const getChat = async (payload, metadata) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': metadata.apikey,
      'Cache-Control': 'no-cache',
      'Connection': 'keep-alive',
    },
    data: payload
  };

  try {
    const axiosInstance = axios.create({
      baseURL: metadata.endpoint_url,
      validateStatus: function (status) {
        return status >= 200 && status < 300;
      },
    });

    const response = await axiosInstance(requestOptions);
    return response.data.choices[0].message.content;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message || 'Server responded with an error');
    } else if (error.request) {
      throw new Error('No response received from the server');
    } else {
      throw new Error(error.message || 'An error occurred while setting up the request');
    }
  }
};