const CodeBlock = ({
  language,
  value,
  styles,
  className,
  props,
}) => {
  return (
    <div className="code-block">
      <div className={styles.codeHeader}>
        <div className={styles.codeHeaderWrapper}>
          <p>{language}</p>
         
        </div>
        <pre style={{ padding: "5px", marginBottom: '0px' }}>
          <code className={className} {...props}>
            {value}
          </code>
        </pre>
        {/* <SyntaxHighlighter
          style={oneLight}
          language={language.toLowerCase()}
          PreTag="div"
          showInlineLineNumbers={true}
          showLineNumbers={true}
          wrapLines={false}
          wrapLongLines={true}
        >
          {String(value)?.replace(/\n$/, "")}
          {/* {code} */}
        {/* </SyntaxHighlighter> */}
      </div>
    </div>
  );
};

export default CodeBlock;
