import React, { useState, useEffect, useContext } from "react";
import { MetadataContext } from "../../context/MetadataContext";
import ChatInput from "./ChatInput";
import { getChat } from "../../services/api";
import { useChatMessages } from "../../context/ChatProvider";
import { motion } from "framer-motion";
import CommonMarkdown from "../CommonMarkdown/CommonMarkdown";
import ScrollToBottom from "react-scroll-to-bottom";
import { throttle } from "lodash";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

function Chatbot({ setChatbotVisible, array, setArray }) {
  const [loading, setLoading] = useState(false);
  const [activeStream, setActiveStream] = useState(false);
  const [input, setInput] = useState("");
  const [conversationId, setCoversationId] = useState("");
  // const { messages, clearMessages, replaceMessage } = useChatMessages();
  // const [array, setArray] = useState(messages)
  const [showChatbot, setShowChatbot] = useState(false);

  const metadata = useContext(MetadataContext);

  const loading_bar_color = `no-repeat radial-gradient(circle closest-side,${metadata.primary_color} 90%,#0000)`;

  useEffect(() => {
    setShowChatbot(true);
  }, []);
  // useEffect(() => {
  //   scrollToBottom();
  // }, [array]);

  // useEffect(() => {
  //   replaceMessage(array)
  // }, [array])

  const throttledUpdateAnswers = throttle((answer) => {
    setArray((prevData) => {
      if (!prevData || prevData.length === 0) return prevData; // Safeguard against undefined and empty array
      const lastMessage = prevData[prevData.length - 1];
      if (lastMessage && lastMessage.text !== answer) {
        const updatedData = [...prevData];
        updatedData[updatedData.length - 1].text = answer;
        return updatedData;
      }
      return prevData;
    });
  }, 500);

  const readResponseBody = async (reader) => {
    setActiveStream(true)
    let buffer;
    let actualContent = "";
    while (true) {
      const { value, done } = await reader.read();
      // console.log('value', value)
      if (done) {
        setActiveStream(false)
        break;
      } else {
        if (value) {
          let chunk = new TextDecoder().decode(value, { stream: true });
          // console.log("received: ", counter, chunk);
          setLoading(false);
          buffer += chunk;

          // Split the buffer into possible JSON objects
          const parts = buffer.split('\ndata: data:').filter(Boolean);

          for (let index = 0; index < parts.length; index++) {
            const element = parts[index].trim();
            if (element) {
              try {
                let jsonStr = element.startsWith('data: data:') ? element.slice(11) : element;
                if (jsonStr.includes('data: [DONE]')) {
                  jsonStr = jsonStr.replace('data: [DONE]', '');
                }
                const parsedJson = JSON.parse(jsonStr);

                if (parsedJson.choices && parsedJson.choices[0] && parsedJson.choices[0].delta && parsedJson.choices[0].delta.content) {
                  const deltaContent = parsedJson.choices[0].delta.content;
                  actualContent += deltaContent;
                  throttledUpdateAnswers(actualContent)
                }
                // Successfully parsed, reset buffer for this part
                buffer = buffer.replace(element, '');
              } catch (error) {
                // If JSON.parse fails, it means this part is incomplete
                // Keep the buffer intact for the next chunk
                if (index === parts.length - 1) {
                  buffer = element;
                }
              }
            }
          }
        } else {
          break;
        }
      }
    }
  }

  const updateAnswers = (answer) => {
    setArray((prevData) => {
      const lastMessage = prevData[prevData.length - 1];
      if (lastMessage && lastMessage.text !== answer) {
        const updatedData = [...prevData];
        updatedData[updatedData.length - 1].text = answer;
        return updatedData;
      }
      return prevData;
    });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const newMessage = { text: input, fromUser: true };
    const newArray = [...array, newMessage, { text: "", fromUser: false }];
    setArray(newArray);
    setInput("");
    setLoading(true);
    let messagesPayload = [];

    for (let i = Math.max(0, array.length - 12); i < array.length; i++) {
      const role = array[i].fromUser ? "user" : "assistant";
      if (messagesPayload.length === 0 && role === "assistant") {
        continue
      } else {
        if (array[i].text.startsWith("Error -")){
          if (messagesPayload.length > 0){
            messagesPayload.pop();
          }
        }else{
          messagesPayload.push({ role, content: array[i].text });
        }
      }
    }

    messagesPayload.push({ role: "user", content: input });

    const payload = {
      model: "eus1",
      messages: messagesPayload,
      stream: false,
      temperature: 0.7,
    };

    try {
      const data = await getChat(payload, metadata);
      // await readResponseBody(data?.getReader());
      throttledUpdateAnswers(data);
      setLoading(false);
    } catch (error) {
      throttledUpdateAnswers(`Error - ${error.message}`);
      setLoading(false);
    }

  };

  const handleClearMessages = () => {
    setCoversationId("");
    setArray([{ text: metadata.welcome_message, fromUser: false }])
  };

  return (
    <div className={`chatbot-container ${showChatbot ? "show" : ""}`}>
      <div className="chatbot-header" style={{ backgroundColor: metadata.primary_color }}>
        <div>
          <img
            src={metadata.logo_png}
            alt="logo"
            style={{ maxWidth: "150px", maxHeight: "25px", paddingLeft: "5px", paddingTop: "10px", paddingBottom: "10px", marginBottom: "-5px" }}
          />
        </div>
        <div className="chatbot-buttons">
          <FontAwesomeIcon icon={faTrash} style={{ width: "16px", height: "16px", color: "#fff", cursor: "pointer" }} onClick={handleClearMessages} />
          <FontAwesomeIcon icon={faXmark} style={{ width: "20px", height: "20px", paddingLeft: "15px", color: "#fff", cursor: "pointer" }} onClick={() => setChatbotVisible(false)} />
        </div>
      </div>
      <ScrollToBottom mode="bottom" className="chatbot-messages" followButtonClassName="hidebutton">
        {array
          .filter(message => message.text.trim() !== "")
          .map((message, index) => {
            const isError = message.text.startsWith("Error -");
            const messageClass = message.fromUser ? "user-message" : "bot-message";
            const containerClass = message.fromUser ? "user-message-container" : "bot-message-container";
            const errorStyle = isError ? { color: 'red', fontStyle: 'italic' } : {};

            return (
              <div key={index} className={containerClass}>
                <motion.div
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.25 }}
                  className={messageClass}
                  style={errorStyle}
                >
                  <CommonMarkdown content={message.text} />
                </motion.div>
              </div>
            );
          })}
        {loading && (
          <div className="bot-message">
            <div className="loader" style={{ '--_g': loading_bar_color }}></div>
            <div className="loader-text" style={{ color: metadata.secondary_color, fontSize: "8px" }}>Finding and Validating Information</div>
          </div>
        )}
        {/* <div ref={messagesEndRef} /> */}
      </ScrollToBottom>
      <div className="chatbot-input">
        <ChatInput
          loading={activeStream}
          input={input}
          setInput={setInput}
          handleSubmit={handleSubmit}
        />
      </div>
    </div>
  );
}

export default Chatbot;