import React, { createContext, useContext, useState } from "react";
import { MetadataContext } from "../context/MetadataContext";

const ChatMessagesContext = createContext();

export const ChatMessagesProvider = ({ children }) => {
  const metadata = useContext(MetadataContext);

  const initialMessage = { text: metadata.welcome_message, fromUser: false };
  const [messages, setMessages] = useState([initialMessage]);

  const addMessage = (message) => {
    setMessages((prevMessages) => [...prevMessages, message]);
  };

  const clearMessages = () => {
    setMessages([initialMessage]);
  };

  const replaceMessage = (array) => {
    setMessages(array)
  }

  const updateLastMessage = (newText, fromUser) => {
    setMessages((prevMessages) => {
      if (prevMessages.length === 0) {
        return prevMessages; // No messages to update
      }
      const lastMessageIndex = prevMessages.length - 1;
      const lastMessage = prevMessages[lastMessageIndex];

      // Check if the last message was added by the user
      if (fromUser && lastMessage.fromUser) {
        return prevMessages; // Skip updating if it's the user's last message
      }

      // Otherwise, update the last message
      const updatedMessages = [...prevMessages];
      updatedMessages[lastMessageIndex] = { ...lastMessage, text: newText };

      return updatedMessages;
    });
  };

  return (
    <ChatMessagesContext.Provider value={{ messages, addMessage, clearMessages, updateLastMessage, replaceMessage }}>
      {children}
    </ChatMessagesContext.Provider>
  );
};

export const useChatMessages = () => {
  return useContext(ChatMessagesContext);
};
