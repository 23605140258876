import React, { useState, useContext } from 'react';
import './App.css';
import Chatbot from './components/chatbot/Chatbot';
import { MetadataContext } from './context/MetadataContext';


function App({ buttonPosition = 'bottom-right' }) {
  const metadata = useContext(MetadataContext);

  const [chatbotVisible, setChatbotVisible] = useState(false);
  const [array, setArray] = useState([{
    text: metadata.welcome_message,
    fromUser: false
  }]);

  const toggleChatbot = () => {
    setChatbotVisible(!chatbotVisible);
  };

  return (
    <div className={`embed-chatbot-wrapper ${buttonPosition}`}>

      {chatbotVisible &&
        <Chatbot
          setChatbotVisible={setChatbotVisible}
          array={array}
          setArray={setArray}
        />
      }
      {!chatbotVisible &&
        <img
          className="embed-toggle-chatbot-btn"
          src={metadata.icon_png}
          onClick={toggleChatbot}
        />
      }
    </div>
  );
}

export default App;